/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import * as React from 'react'

import { IconButton as MuiIconButton, IconButtonProps as DefaultIconButtonProps, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

interface IconButtonProps extends DefaultIconButtonProps {
    tooltip?: string
    variant?: 'outlined' | 'contained' | 'default'
}

const DefaultIconButton = styled(MuiIconButton)`
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`

const OutlinedIconButton = styled(DefaultIconButton)`
    border: 1px solid ${({ theme, color }) => theme.palette[color]?.dark || theme.palette.grey[300]};
    &:hover {
        border-color: ${({ theme, color }) => theme.palette[color]?.main || theme.palette.grey[100]};
    }
`

const IconButton = (props: IconButtonProps) => {
    const { variant = 'default', color = 'inherit' } = props

    const IconButtonComponent = variant === 'outlined' ? OutlinedIconButton : DefaultIconButton

    const IconContent = (Variant: React.ElementType) => (
        <Variant color={color} {...props}>
            {props.children}
        </Variant>
    )

    return props.tooltip ? (
        <Tooltip arrow title={props.tooltip}>
            <span>{IconContent(IconButtonComponent)}</span>
        </Tooltip>
    ) : (
        IconContent(IconButtonComponent)
    )
}

export default IconButton
