import { Grid, Box } from '@mui/material'
import LinkButton from 'components/atomic/atoms/link-button'
import useTranslation from 'next-translate/useTranslation'
import * as React from 'react'
import { analytics } from 'utils/analytics'

const LoginFormLayout = ({ children }: { children: JSX.Element }) => {
    const { t } = useTranslation('common')

    const handleTrack = () => {
        analytics.track('help_click', { type: 'supportPage' })
    }

    return (
        <Box>
            <Grid container display="flex" alignItems="center" justifyContent="center" minHeight="95vh">
                <Grid item xs={9} sm={6} lg={3} sx={{ py: 4 }}>
                    <Box alignItems="center" justifyContent="center">
                        {children}
                    </Box>
                </Grid>
            </Grid>
            <a href="https://systematic-raven.super.site/">
                <LinkButton onClick={handleTrack} sx={{ textAlign: 'center' }} dataCy="get_support">
                    {t('supportMsg')}
                </LinkButton>
            </a>
        </Box>
    )
}

export default LoginFormLayout
