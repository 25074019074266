import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import React from 'react'

import AppLogo from 'components/atomic/atoms/logo'
import SocialButton from '../atoms/social-button'
import useTranslation from 'next-translate/useTranslation'
const LoginWelcomeMessage = React.memo(() => {
    const { palette } = useTheme()
    const { t } = useTranslation('common')
    return (
        <Box>
            <AppLogo />

            <Typography
                variant="h2"
                sx={{
                    mt: 2,
                    mb: 1,
                }}
                textAlign="start">
                {t('welcome')} 👋
            </Typography>

            <Typography
                textAlign="start"
                style={{
                    fontWeight: 300,
                    fontSize: 16,
                }}
                sx={{
                    mb: 3,
                }}>
                {t('welcomeMessage')}
            </Typography>

            <SocialButton social="Google" actionType="Login" />
            {/* <SocialButton social="Apple" signMode="Login" /> */}

            <Typography
                style={{
                    color: palette.font.secondary,
                }}
                sx={{
                    pt: 2,
                    pb: 3,
                }}>
                {t('logWith')}
            </Typography>
        </Box>
    )
})

export default LoginWelcomeMessage
