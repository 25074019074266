import * as React from 'react'
import * as AuthUtils from 'utils/backend/sdk/auth'
import * as FirebaseUtils from 'utils/backend/sdk/firebase'
import * as TestySdk from 'utils/backend/sdk/testy-web-sdk'

import { Button, Typography, useTheme } from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/router'

import Routes from 'utils/routes'
import { AuthContext } from 'components/providers/auth-provider'
import { useSnackbar } from 'notistack'
import useTranslation from 'next-translate/useTranslation'
import { analytics } from 'utils/analytics'

interface SocialButtonProps {
    social: 'Apple' | 'Google'
    actionType: 'Login' | 'Signup'
}

const SocialButton = React.memo<SocialButtonProps>((props) => {
    const { setUser } = React.useContext(AuthContext)
    const { palette } = useTheme()
    const router = useRouter()
    const snackbar = useSnackbar()

    const { t } = useTranslation('common')

    const handleClick = async () => {
        if (props.social === 'Apple') {
            // await signWithApple()
            // router.push(Routes.Home)
        } else if (props.social === 'Google') {
            const user = await FirebaseUtils.signWithGoogle()
            const fbToken = await user?.result.user.getIdToken()
            const token = await AuthUtils.exchangeFirebaseTokenWithCustom(fbToken || '')
            TestySdk.setAuthToken(token.access_token)

            analytics.track(props.actionType === 'Login' ? 'signin_google_click' : 'signup_google_click')

            if (user?.result.user.email && setUser) {
                await TestySdk.checkUserExistance({ email: user?.result.user.email })
                    .then(() => {
                        const userBasicData = AuthUtils.userBasicDataParser(user?.result.user, token)
                        setUser(userBasicData)
                        analytics.track('login_success', { provider: 'google' })
                        analytics.identify(`${userBasicData.email}`, {
                            email: userBasicData.email,
                            uid: userBasicData.uid,
                        })
                        router.replace(Routes.Home)
                    })
                    .catch(async () => {
                        TestySdk.createUser({
                            email: user.result.user.email,
                            name: user.result.user.displayName || '',
                        })
                            .then(() => {
                                const userBasicData = AuthUtils.userBasicDataParser(user?.result.user, token)
                                setUser(userBasicData)
                                analytics.identify(`${userBasicData.email}`, userBasicData)
                                analytics.track('sign_up_success', { provider: 'google' })
                                router.push(Routes.Onboarding)
                            })
                            .catch((e) => {
                                analytics.track('sign_up_error', { provider: 'google', error: e })
                                snackbar.enqueueSnackbar(e, { variant: 'error' })
                            })
                    })
            }
        }
    }

    return (
        <Button
            onClick={handleClick}
            variant="outlined"
            sx={{ py: 1, mb: 1, width: '100%', textTransform: 'none', borderColor: palette.border }}>
            <Image
                src={'/images/logo_' + props.social.toLowerCase() + '.png'}
                alt={props.social}
                width={20}
                height={20}
            />
            <Typography variant="caption" color={palette.font.main} sx={{ pl: 1 }}>
                {(props.actionType === 'Signup' ? t('SignUp') : t('LogIn')) + ' con ' + props.social}
            </Typography>
        </Button>
    )
})

export default SocialButton
