import { SxProps, useTheme } from '@mui/material'
import { Typography } from '@mui/material'
import * as React from 'react'

interface LinkButtonProps {
    dataCy: string
    children: JSX.Element
    onClick?: () => void
    sx?: SxProps
}

const LinkButton = React.memo<LinkButtonProps>(({ dataCy, children, onClick, sx }) => {
    const { palette } = useTheme()
    return (
        <Typography
            onClick={onClick ?? undefined}
            variant="helper_text"
            sx={{
                color: palette.primary.main,
                fontWeight: 700,
                textTransform: 'capitalize',
                textDecoration: 'underline',
                fontSize: 12,
                cursor: 'pointer',
                ...sx,
            }}
            data-cy={dataCy}>
            {children}{' '}
        </Typography>
    )
})
export default LinkButton
