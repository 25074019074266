import * as React from 'react'
import * as FirebaseUtils from 'utils/backend/sdk/firebase'

import { CloseOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import FormTextField from '../atoms/form-field-input'
import useTranslation from 'next-translate/useTranslation'
import IconButton from '../atoms/icon-button'

interface RecoveryPasswordModalProps {
    recoverPassModalShow: boolean
    setRecoverPassModalShow: (val: boolean) => void
    identifier: string
    setIdentifier: (e: string) => void
}

const RecoveryPasswordModal = (props: RecoveryPasswordModalProps) => {
    const { palette } = useTheme()
    const [recoverMailSent, setRecoverMailSent] = React.useState(false)
    const { recoverPassModalShow, setRecoverPassModalShow, identifier, setIdentifier } = props

    const handleRecoverPass = async () => {
        try {
            await FirebaseUtils.recoverPassword(identifier)
        } catch (e) {
            throw e
        } finally {
            setRecoverMailSent(true)
        }
    }

    const { t } = useTranslation('common')

    return (
        <Dialog open={recoverPassModalShow} onClose={() => setRecoverPassModalShow(false)}>
            <Container
                maxWidth={'xs'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                }}>
                <IconButton
                    onClick={() => setRecoverPassModalShow(false)}
                    sx={{
                        alignSelf: 'end',
                    }}>
                    <CloseOutlined />
                </IconButton>
                {!recoverMailSent ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}>
                        <Typography variant="h3">{t('passwordBack')}</Typography>
                        <FormTextField
                            helperText=""
                            placeholder={t('passwordMail')}
                            onChangeFn={(e) => setIdentifier(e)}
                        />
                        <Typography
                            color={palette.grey[600]}
                            sx={{
                                py: 3,
                            }}>
                            {t('passwordLink')}
                        </Typography>

                        <Button variant="contained" onClick={() => handleRecoverPass()}>
                            Conferma
                        </Button>
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h3">{t('linkSent')}</Typography>
                        <Typography
                            sx={{
                                py: 2,
                            }}>
                            {t('linkSentMsg')}
                        </Typography>
                    </Box>
                )}
            </Container>
        </Dialog>
    )
}

export default RecoveryPasswordModal
